// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-frequently-asked-questions-tsx": () => import("./../src/pages/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-license-terms-mdx": () => import("./../src/pages/license-terms.mdx" /* webpackChunkName: "component---src-pages-license-terms-mdx" */),
  "component---src-pages-login-tsx": () => import("./../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-purchase-tsx": () => import("./../src/pages/purchase.tsx" /* webpackChunkName: "component---src-pages-purchase-tsx" */),
  "component---src-pages-terms-and-conditions-mdx": () => import("./../src/pages/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mdx" */),
  "component---src-templates-documentation-mdx-tsx": () => import("./../src/templates/documentation-mdx.tsx" /* webpackChunkName: "component---src-templates-documentation-mdx-tsx" */),
  "component---src-templates-reference-entity-tsx": () => import("./../src/templates/reference-entity.tsx" /* webpackChunkName: "component---src-templates-reference-entity-tsx" */)
}

